<template>
  <b-card>
    <b-form-input v-model="client.name" placeholder="Imię i nazwisko"></b-form-input>
    <b-form-input v-model="client.email" class="mt-1" placeholder="Email"></b-form-input>
    <b-form-input v-model="client.phone_number" class="mt-1" placeholder="Telefon"></b-form-input>
    <b-form-input v-model="client.address" class="mt-1" placeholder="Adres"></b-form-input>
    <b-form-input v-model="client.city" class="mt-1" placeholder="Miasto"></b-form-input>
    <b-form-input v-model="client.description" class="mt-1" placeholder="Miasto"></b-form-input>

    <b-button variant="primary" class="mt-2" @click="createClient">Dodaj klienta</b-button>
  </b-card>
</template>

<script>
import { BCard, BFormInput, BButton } from 'bootstrap-vue'
import axios from 'axios'
import swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BFormInput,
    BButton,
  },
  data() {
    return {
      client: {
        name: '',
        email: '',
        phone_number: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        notes: '',
      },
      errors: [],
    }
  },
  methods: {
    createClient() {
      const config = { headers: { authorization: `Bearer ${localStorage.getItem('accessToken')}` } }

      axios
        .post(`${process.env.VUE_APP_API_URL}create-ghost-user`, this.client, config)
        .then(() => {
          this.$router.push({ name: 'Clients' })
          swal.fire('Success', 'Klient został dodany', 'success')
        })
        .catch((e) => {
        })
    }
  }
}
</script>
